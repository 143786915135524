import { ApisauceInstance } from 'apisauce'

/**
 * Expose all endponts for 'account'.
 */
export default (api: ApisauceInstance): ApiObject => ({
  getInfo: () => api.get('account'),
  changePassword: (oldPassword: string, newPassword: string) => api.post('account/change-password', {
    currentPassword: oldPassword,
    newPassword,
  }),
})

