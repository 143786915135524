import { ActionCreatorsMapObject } from 'redux'
import { all, call, put, SagaReturnType } from 'redux-saga/effects'
import { createModule, EffectParam, STATUS } from 'redux-acron'
import dayjs from 'dayjs'
import config from '@config'
import access from '@utils/access'
import authModule from './AuthModule'
import accountModule from './AccountModule'
import api from '@services/api'
import useClientConfig from 'config/clients/useClientConfig'

export interface SurveysCountInfo {
  answered: number
  not_handled: number
  in_progress: number
  closed: number
  fake: number
  invalid: number
}

export type AppDataState = {
  title: string
  menuItems: MenuType[]
  specialFeats: object | null
  filters: {
    fromDate: string
    toDate: string
    logicalDistributions: string | null
    geographicDistributions: string | null
    cluster: string | null
    criticalMoments: string | null
    valueClient: boolean | null
  }
  filtersData: {
    logicalDistributions: any[]
    geographicDistributions: any[]
    geographicList: any[]
    criticalMoments: any[]
    clusters: any[]
    dynamicReportsFields: any[]
    workflows: any[]
  }
  loadingSurveysCount: boolean
  surveysCount: SurveysCountInfo | null
  alertsCount: {
    not_handled: number
    closed: number
    in_process: number
    wows: number
  }
  loadingAlertsCount: boolean
  countersRange: number
}

const getFiltersDefaultFromDate = () => {
  const {getFilterFromDateSubstract} = useClientConfig()
  return dayjs().subtract(getFilterFromDateSubstract(), 'month').format('YYYY-MM-DD')
}

const getFiltersDefaultToDate = () => {
  const {getFilterToDateSubstract} = useClientConfig()
  return dayjs().subtract(getFilterToDateSubstract(), 'month').format('YYYY-MM-DD')
}

/**
 * AppData Module
 * reducer/sagas/actions
 */
const AppDataModule = createModule<AppDataState>({
  name: 'appData',
  state: {
    title: '',
    menuItems: [],
    specialFeats: {},
    filters: {
      fromDate: getFiltersDefaultFromDate(),
      toDate: getFiltersDefaultToDate(),
      logicalDistributions: null,
      geographicDistributions: null,
      cluster: null,
      criticalMoments: null,
      valueClient: null,
    },

    filtersData: {
      logicalDistributions: [],
      geographicDistributions: [],
      geographicList: [],
      criticalMoments: [],
      clusters: [],
      dynamicReportsFields: [],
      workflows: []
    },

    surveysCount: null,
    loadingSurveysCount: false,

    alertsCount: {
      not_handled: 0,
      closed: 0,
      in_process: 0,
      wows: 0,
    },
    loadingAlertsCount: false,
    countersRange: 30,
  },

  handlers: {
    setTitle: (state, { payload }: { payload: { title: string } }) => {
      return state.merge({
        title: payload.title,
      })
    },

    setMenuItems: (
      state,
      { payload }: { payload: { items: Array<MenuType> } }
    ) => {
      return state.merge({
        menuItems: payload.items,
      })
    },

    setFiltersData: (state, { payload }) => {
      return state.merge({
        filtersData: payload,
      })
    },

    setFilters: (state, { payload }) => {
      return state.merge({
        filters: payload,
      })
    },

    getSurveyCount: (state, { payload, status }) => {
      if (status === STATUS.PENDING_STATUS) {
        return state.merge({
          surveysCount: null,
          loadingSurveysCount: true
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({ 
          loadingSurveysCount: false,
          surveysCount: payload.data
         })
      }

      return state
    },

    getAlertsCount: (state, { payload, status }) => {
      if (status === STATUS.PENDING_STATUS) {
        return state.merge({
          loadingAlertsCount: true,
          countersRange: payload.range,
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          loadingAlertsCount: false,
          alertsCount: payload.data,
          countersRange: payload.range,
        })
      }
      return state
    },

    [authModule.actionsTypes.signin]: (
      state,
      { payload, status }: ActionPayload<{ data: UserAccount }>
    ) => {
      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          specialFeats: config.specialFeats?.[payload.data.tenantId],
          menuItems: access.pages.getMenuItems(
            payload.data.authorities,
            payload.data?.userProfiles?.[0]?.config.access
          ),
          filtersData: {
            logicalDistributions: payload.data.criticalMoments,
            geographicDistributions: payload.data.geoLocations,
            criticalMoments: payload.data.criticalMoments,
            clusters: payload.data.clusters,
            dynamicReportsFields: payload.data.dynamicReportsFields,
            workflows: payload.data.workflows,
          },
          filters: {
            ...state.filters,
            logicalDistributions:
              payload.data.criticalMoments && payload.data.criticalMoments.length > 0
                ? payload.data.criticalMoments.map(logical => logical.id)
                : [],
            geographicDistributions:
              payload.data.geoLocations && payload.data.geoLocations.length > 0
                ? payload.data.geoLocations.map(geo => geo.id)
                : [],
            criticalMoments: [
              payload.data.criticalMoments && payload.data.criticalMoments[0]
                ? payload.data.criticalMoments[0].id
                : undefined,
            ],
            cluster: [
              payload.data.clusters && payload.data.clusters[0]
                ? payload.data.clusters[0].id
                : undefined,
            ]
          },
        })
      }
      return state
    },

    [accountModule.actionsTypes.getInfo]: (
      state,
      { payload, status }: ActionPayload<{ data: UserAccount }>
    ) => {
      if (status === STATUS.SUCCESS_STATUS) {
        const filters =
          payload.data.authorities.indexOf('ROLE_ADMIN') === -1 &&
            payload.data.authorities.indexOf('ROLE_ROOT') === -1
            ? {
              logicalDistributions: payload.data.logicalLocations
                && payload.data.logicalLocations.map(logical => logical.id),
              geographicDistributions: payload.data.geoLocations
                && payload.data.geoLocations.map(geo => geo.id),
              criticalMoments: payload.data.criticalMoments.length > 0
                ? [payload.data.criticalMoments[0].id]
                : [],
              clusters: payload.data.clusters.length > 0
                ? [payload.data.clusters[0].id] : [],
            }
            : {}

        return state.merge({
          specialFeats: config.specialFeats?.[payload.data.tenantId],
          menuItems: access.pages.getMenuItems(
            payload.data.authorities,
            payload.data?.userProfiles?.[0]?.config.access
          ),
          filtersData: {
            logicalDistributions: payload.data.logicalLocations,
            geographicDistributions: payload.data.geoLocations,
            criticalMoments: payload.data.criticalMoments,
            clusters: payload.data.clusters,
            dynamicReportsFields: payload.data.dynamicReportsFields,
            workflows: payload.data.workflows,
          },
          filters: {
            ...state.filters,
            ...filters,
          },
        })
      }

      return state
    },
  },
  effects: {
    getSurveyCount: {
      *callback(
        actions: ActionCreatorsMapObject,
        { payload }: EffectParam<{ filters: object; range: number }>
      ) {
        type ResponseType = SagaReturnType<typeof api.surveysResponse.vocCounters>
        const response: ResponseType = yield call(
          api.surveysResponse.vocCounters,
          payload.filters
        )

        if (response.ok) {
          yield put(
            actions.getSurveyCount(
              {
                data: response.data,
              },
              STATUS.SUCCESS_STATUS
            )
          )
        }
      },
    },

    getAlertsCount: {
      *callback(
        actions: ActionCreatorsMapObject,
        { payload }: EffectParam<{ filters: object; range: number }>
      ) {
        type DataResponseType = SagaReturnType<typeof api.alerts.list>
        const responses: DataResponseType[] = yield all([
          api.alerts.list(0, 1, {
            ...payload.filters,
            alertStatusInclude: 'NOT_HANDLED',
            alertTypeInclude: 'INCIDENT,RESCUE,ABANDON',
          }),

          api.alerts.list(0, 1, {
            ...payload.filters,
            alertStatusInclude: 'IN_PROCESS',
          }),

          api.alerts.list(0, 1, {
            ...payload.filters,
            alertStatusInclude: 'CLOSED',
          }),

          api.alerts.list(0, 1, {
            ...payload.filters,
            alertTypeInclude: 'WOW',
          }),
        ])

        const responseOk = responses.reduce((result, value) => {
          result = result && value.ok
          return result
        }, true)

        if (responseOk) {
          yield put(
            actions.getAlertsCount(
              {
                range: payload.range,
                data: {
                  not_handled: Number(responses[0].headers?.['x-total-count']),
                  in_process: Number(responses[1].headers?.['x-total-count']),
                  closed: Number(responses[2].headers?.['x-total-count']),
                  wows: Number(responses[3].headers?.['x-total-count']),
                },
              },
              STATUS.SUCCESS_STATUS
            )
          )
        }
      },
    },
  },
})

export default AppDataModule
