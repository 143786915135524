

import defaultOxxo from '../dashboard/oxxo/default'
import demoHa from '../dashboard/ha/demo'
import extended from '../dashboard/oxxo/extended'
import newFeatures from '../dashboard/oxxo/newFeatures'
import defaultGnp from '../dashboard/gnp/default'
import defaultCaffenio from '../dashboard/caffenio/default'
import defaultIamsa from '../dashboard/iamsa/default'
import defaultBenavides from '../dashboard/benavides/default'
import defaultProfuturo from '../dashboard/profuturo/default'
import demoJac from '../dashboard/jac/demo'

// Mitsubishi
import mitsuCxo from '../dashboard/mitsubishi/cxo'
import mitsuDistribuidor from '../dashboard/mitsubishi/distribuidor'
import mitsuDistribuidorPosventa from '../dashboard/mitsubishi/distribuidorPosventa'
import mitsuDistribuidorVenta from '../dashboard/mitsubishi/distribuidorVenta'
import mitsuNacionalPosventa from '../dashboard/mitsubishi/nacionalPosventa'
import mitsuNacionalVenta from '../dashboard/mitsubishi/nacionalVenta'
import mitsuZonalPosventa from '../dashboard/mitsubishi/zonalPosventa'
import mitsuZonalVenta from '../dashboard/mitsubishi/zonalVenta'
import mitsuNacionalReparacion from '../dashboard/mitsubishi/nacionalReparacion'
import mitsuZonalReparacion from '../dashboard/mitsubishi/zonalReparacion'

// Suzuki
import suzuCxo from '../dashboard/suzuki/cxo'
import suzuDistribuidor from '../dashboard/suzuki/distribuidor'
import suzuDistribuidorPosventa from '../dashboard/suzuki/distribuidorPosventa'
import suzuDistribuidorVenta from '../dashboard/suzuki/distribuidorVenta'
import suzuNacionalPosventa from '../dashboard/suzuki/nacionalPosventa'
import suzuNacionalVenta from '../dashboard/suzuki/nacionalVenta'
import suzuZonalPosventa from '../dashboard/suzuki/zonalPosventa'
import suzuZonalVenta from '../dashboard/suzuki/zonalVenta'

// Byd
import bydCxo from '../dashboard/byd/cxo'
import bydNacionalServicio from '../dashboard/byd/nacionalServicio'
import bydNacionalVenta from '../dashboard/byd/nacionalVenta'
import { SECTION_KEYS } from '@utils/access/perms'
/*import bydDistribuidor from '../dashboard/byd/distribuidor'
import bydDistribuidorPosventa from '../dashboard/byd/distribuidorPosventa'
import bydDistribuidorVenta from '../dashboard/byd/distribuidorVenta'
import bydZonalPosventa from '../dashboard/byd/zonalPosventa'
import bydZonalVenta from '../dashboard/byd/zonalVenta'*/


export interface DashboardList {
    [id: string]: DasboardConfig
}

export interface DashboardConfigInfo {
    path: string
    title: string
    reducerKey: string
    dashboards: DashboardList
}

export interface ClientDashboardConfig {
    [DASHBOARDS.DEFAULT]: DashboardConfigInfo
    [id: string]: DashboardConfigInfo
}

export interface ClientDashboardsList {
    [client: string]: ClientDashboardConfig
}

export enum DASHBOARDS {
    DEFAULT = 'dashboard', //SECTION_KEYS.DASHBOARD,
    OXXO_EXT = 'extended-dashboard', //SECTION_KEYS.EXTENDED_DASHBOARD,
    //OXXO_DISEL = 'dashboard-disel',
    //OXXO_QR = 'dashboard-qr',
    //OXXO_BANIOS = 'dashboard-banios',
}

export const DASHBOARDS_CONFIGS: ClientDashboardsList = {
    oxxo: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Panel de indicadores',
            reducerKey: 'dashboard',
            dashboards: { demo: defaultOxxo, newFeatures: newFeatures }
        },
        [DASHBOARDS.OXXO_EXT]: {
            path: '/extended-dashboard',
            title: 'Preguntas complementarias',
            reducerKey: SECTION_KEYS.EXTENDED_DASHBOARD,
            dashboards: { extended: extended }
        },
        /*[DASBOARDS.OXXO_DISEL]: {
            path: '/dashboard-disel',
            title: 'New features',
            reducerKey: 'dashboard-disel',
            dashboards: { disel: disel }
        },
        [DASBOARDS.OXXO_QR]: {
            path: '/dashboard-qr',
            title: 'Dashboard QR',
            reducerKey: 'dashboard-qr',
            dashboards: { qr: qr }
        
        [DASHBOARDS.OXXO_BANIOS]: {
            path: '/dashboard-banios',
            title: 'Dashboard ',
            reducerKey: SECTION_KEYS.DASHBOARD_BANIOS,
            dashboards: { banios: banios }
        },
        */
    },
    mitsubishi: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: {
                'cxo': mitsuCxo,
                'nacionalVenta': mitsuNacionalVenta,
                'nacionalPosventa': mitsuNacionalPosventa,
                'nacionalReparacion': mitsuNacionalReparacion,
                'zonalVenta': mitsuZonalVenta,
                'zonalPosventa': mitsuZonalPosventa,
                'zonalReparacion': mitsuZonalReparacion,
                'distribuidorVenta': mitsuDistribuidorVenta,
                'distribuidorPosventa': mitsuDistribuidorPosventa,
                'distribuidor': mitsuDistribuidor
            }
        }
    },
    suzuki: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: {
                'cxo': suzuCxo,
                'nacionalVenta': suzuNacionalVenta,
                'nacionalPosventa': suzuNacionalPosventa,
                'zonalVenta': suzuZonalVenta,
                'zonalPosventa': suzuZonalPosventa,
                'distribuidor': suzuDistribuidor,
                'distribuidorVenta': suzuDistribuidorVenta,
                'distribuidorPosventa': suzuDistribuidorPosventa
            }
        }
    },
    ha: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: { demo: demoHa }
        }
    },
    gnp: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: { demo: defaultGnp }
        }
    },
    profuturo: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: {
                demo: defaultProfuturo,
                marketing: defaultProfuturo,
                //corporativo: defaultProfuturo,
                //regional: defaultProfuturo,
                //operacional: defaultProfuturo,
                //asesor: defaultProfuturo,
                //lider: defaultProfuturo
            }
        }
    },
    jac: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: {
                demo: demoJac,
            }
        }
    },
    caffenio: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: { demo: defaultCaffenio }
        }
    },
    iamsa: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: { demo: defaultIamsa }
        }
    },
    byd: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: {
                'cxo': bydCxo,
                'nacionalVenta': bydNacionalVenta,
                'nacionalPosventa': bydNacionalServicio,
                /*'zonalVenta': bydZonalVenta,
                'zonalPosventa': bydZonalPosventa,
                'distribuidor': bydDistribuidor,
                'distribuidorVenta': bydDistribuidorVenta,
                'distribuidorPosventa': bydDistribuidorPosventa*/
            }
        }
    },
    benavides: {
        [DASHBOARDS.DEFAULT]: {
            path: '/dashboard',
            title: 'Dashboard',
            reducerKey: 'dashboard',
            dashboards: { demo: defaultBenavides }
        }
    },
}